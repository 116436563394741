html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
:root {
  --ratio: 1.14;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.4);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.4);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.4);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.4);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.18;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.22;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
html * {
  will-change: outline-offset;
  transition-property: outline-offset;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  outline-offset: 0;
}
html[data-focus-source="key"] *:focus,
html[data-focus-source="script"] *:focus {
  z-index: 90;
  outline-offset: 6px;
  outline: 2px dashed #fa7d00;
  overflow: visible;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
.application_container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: #f5f5f5;
}
@media only screen and (min-width: 881px) {
  .application_container {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .application_container {
    height: 100vh;
    max-height: 100vh;
  }
}
.global_header,
.global_navigation {
  flex: 0 0 auto;
}
.main_content {
  order: 3;
  flex: 1;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media only screen and (min-width: 881px) {
  .main_content {
    order: initial;
  }
}
@media only screen and (min-width: 881px) {
  .main_content {
    margin-right: 7rem;
    margin-right: calc(7rem + 1px);
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
  }
}
@media only screen and (min-width: 881px) {
  .main_content--user_not_signed_in {
    margin-right: 0;
  }
}
* + .reading_content {
  margin-top: 1.618rem;
}
figure,
img,
video {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + figure,
* + img,
* + video {
  margin-top: 1.618rem;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
select,
input,
textarea {
  border: 0;
}
input,
button {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
:root {
  font-size: 12px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.4vw + 10.08px);
  }
}
@media screen and (min-width: 1480px) {
  :root {
    font-size: 16px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  line-height: 1.618;
  letter-spacing: -0.006em;
  font-size: inherit;
  color: #3a393c;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #19191a;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  color: #650df2;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus {
  color: #520ab8;
}
h1 {
  max-width: 45.6ch;
  font-size: 1.68896016rem;
  font-size: var(--fz-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 1.93877776rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.21533456rem;
    font-size: var(--fz-ratio-power--four);
  }
}
* + h1 {
  margin-top: 2.364544223999999rem;
  margin-top: var(--sp-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  * + h1 {
    margin-top: 2.714288863999999rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  * + h1 {
    margin-top: 3.101468383999999rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
h2 {
  max-width: 53.199999999999996ch;
  font-size: 1.481544rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.643032rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 1.815848rem;
    font-size: var(--fz-ratio-power--three);
  }
}
* + h2 {
  margin-top: 2.0741616rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.3002448rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 2.5421872rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 57.76ch;
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.4884rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + h3 {
  margin-top: 1.81944rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 1.94936rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.08376rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 60.800000000000004ch;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
* + h4 {
  margin-top: 1.596rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.652rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.708rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  color: #3a393c;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p {
  margin-top: 1.2135rem;
}
h3 + p,
h4 + p {
  margin-top: 1.077588rem;
}
.reading_content p,
p.reading_content {
  width: 76ch;
}
.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited {
  color: #650df2;
}
.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus {
  color: #520ab8;
}
a,
a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  cursor: pointer;
  text-decoration: none;
}
b,
strong {
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
i,
em {
  font-style: italic;
  font-family: "DM Sans", sans-serif;
}
::selection {
  background-color: #7547f5;
  text-shadow: 0 0 0.1em #520ab8;
  color: #fff;
}
.reading_content {
  max-width: 100%;
}
.reading_content ol,
.reading_content ul {
  display: block;
  width: 76ch;
  max-width: 100%;
  padding-left: 3ch;
}
* + .reading_content ol,
* + .reading_content ul {
  margin-top: 1.618rem;
}
.reading_content ol:not(first-child),
.reading_content ul:not(first-child) {
  margin-top: 1.618rem;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol {
  list-style-type: lower-roman;
}
.reading_content ul {
  list-style-type: disc;
}
.reading_content ul ul {
  list-style-type: circle;
}
.reading_content ul ul ul {
  list-style-type: square;
}
.reading_content li {
  max-width: 100%;
}
.reading_content li + li {
  margin-top: 0.809rem;
}
sup {
  vertical-align: super;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_header {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3a393c;
  padding: 0.809rem 4vw;
}
@media only screen and (min-width: 881px) {
  .global_header {
    flex-direction: column;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    box-shadow: 2px 0 2px rgba(25,25,26,0.08);
    border-right: 1px solid #3a393c;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    padding: 0;
  }
}
.global_navigation__account_options {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options {
    flex-direction: column-reverse;
    margin-top: auto;
  }
}
.global_navigation__account_options--is_details_element {
  display: flex;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options--is_details_element {
    display: none;
  }
}
.global_navigation__account_options--is_div_element {
  display: none;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options--is_div_element {
    display: flex;
  }
}
.global_navigation__account_options_menu_toggle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.077588rem 0 1.077588rem 4vw;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_toggle {
    min-width: 7rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_toggle {
    align-items: center;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_toggle {
    margin-left: auto;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_toggle {
    padding: 1.618rem;
  }
}
.global_navigation__account_options_menu_toggle::-webkit-details-marker {
  display: none;
}
.global_navigation__account_options_icon {
  flex: 0 0 auto;
  height: 2.427rem;
  width: 2.427rem;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_icon {
    height: 2.8315rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_icon {
    width: 2.8315rem;
  }
}
.global_navigation__account_options_icon_path {
  will-change: fill;
  transition-property: fill;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  fill: #650df2;
}
.global_navigation__account_options:active .global_navigation__account_options_icon_path,
.global_navigation__account_options:focus .global_navigation__account_options_icon_path,
.global_navigation__account_options:hover .global_navigation__account_options_icon_path {
  fill: #520ab8;
}
.global_navigation__account_options_menu_toggle_text {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  flex: 0 0 auto;
  display: none;
  text-transform: uppercase;
  color: #650df2;
  font-weight: 600;
  font-size: 14px;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_toggle_text {
    display: block;
  }
}
* + .global_navigation__account_options_menu_toggle_text {
  margin-top: 0.539333333333333rem;
}
.global_navigation__account_options:active .global_navigation__account_options_menu_toggle_text,
.global_navigation__account_options:focus .global_navigation__account_options_menu_toggle_text,
.global_navigation__account_options:hover .global_navigation__account_options_menu_toggle_text {
  color: #520ab8;
}
.global_navigation__account_options_menu_list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-right: -100%;
  width: 200%;
  max-width: 200%;
  background-color: #f5f5f5;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_list {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_list {
    margin-right: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_list {
    width: 18rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_list {
    max-width: 100%;
    border-bottom: 1px solid #3a393c;
  }
}
.global_navigation__account_options_menu_list--is_in_div_element {
  display: none;
}
.global_navigation__account_options_menu_list[open] {
  display: flex;
}
.global_navigation__account_options_menu_item {
  border-top: 1px solid #3a393c;
}
.global_navigation__account_options_menu_anchor {
  display: block;
  padding: 1.618rem 4%;
  color: #650df2;
  font-weight: 600;
  font-size: 17px;
  overflow-wrap: normal;
}
@media only screen and (min-width: 881px) {
  .global_navigation__account_options_menu_anchor {
    padding: 1.618rem;
  }
}
.global_navigation__account_options_menu_anchor:active,
.global_navigation__account_options_menu_anchor:focus,
.global_navigation__account_options_menu_anchor:hover {
  color: #520ab8;
}
.global_navigation {
  background-color: #f5f5f5;
}
.global_navigation__menu {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu {
    flex: 1;
  }
}
.global_navigation__menu_toggle {
  cursor: pointer;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 4.583794rem;
  padding: 1.077588rem 4vw 1.077588rem 0;
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_toggle {
    height: auto;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_toggle {
    padding: 0;
  }
}
.global_navigation__menu_toggle::-webkit-details-marker {
  display: none;
}
.global_navigation__menu_toggle_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_toggle_inner {
    min-width: 7rem;
    padding: 1.618rem 1.618rem 1.077588rem;
  }
}
.global_navigation__menu_toggle_icon {
  flex: 0 0 auto;
}
.global_navigation__menu_toggle_icon_bar {
  display: block;
  height: 4px;
  width: 2.427rem;
  border-radius: 3px;
  background-color: #650df2;
}
* + .global_navigation__menu_toggle_icon_bar {
  margin-top: 6px;
}
@media only screen and (min-width: 681px) {
  .global_navigation__menu_toggle_icon_bar {
    width: 2.8315rem;
  }
}
.global_navigation__menu_toggle_text {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  flex: 0 0 auto;
  display: none;
  text-transform: uppercase;
  color: #650df2;
  font-weight: 600;
  font-size: 16px;
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_toggle_text {
    display: block;
  }
}
* + .global_navigation__menu_toggle_text {
  margin-top: 0.539333333333333rem;
}
.global_navigation__menu_toggle_text:active,
.global_navigation__menu_toggle_text:focus,
.global_navigation__menu_toggle_text:hover {
  color: #520ab8;
}
.global_navigation__menu_list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: -100%;
  width: 200%;
  max-width: 200%;
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_list {
    margin-left: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_list {
    width: 18rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_list {
    max-width: 100%;
    border-bottom: 1px solid #3a393c;
  }
}
.global_navigation__menu_item {
  border-top: 1px solid #3a393c;
}
.global_navigation__menu_anchor {
  display: block;
  padding: 1.618rem 4%;
  color: #650df2;
  font-weight: 600;
  font-size: 17px;
}
@media only screen and (min-width: 881px) {
  .global_navigation__menu_anchor {
    padding: 1.618rem;
  }
}
.global_navigation__menu_anchor:active,
.global_navigation__menu_anchor:focus,
.global_navigation__menu_anchor:hover {
  color: #520ab8;
}
.global_navigation {
  background-color: #f5f5f5;
  transform: translate3d(0, 0, 0);
  z-index: 90;
  order: 2;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
  border-bottom: 1px solid #3a393c;
  padding: 0;
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    order: initial;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    flex-direction: column;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    align-items: stretch;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    box-shadow: -2px 0 2px rgba(25,25,26,0.08);
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    border-left: 1px solid #3a393c;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    border-bottom: 0;
  }
}
.action_anchor_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -1.618rem;
  width: 100%;
}
* + .action_anchor_container {
  margin-top: 1.618rem;
}
.organisations_and_publications + .action_anchor_container {
  margin-top: 2.427rem;
  padding-top: 1.618rem;
  border-top: 2px dashed #e5e4e7;
}
.action_anchor {
  flex: 0 0 auto;
  will-change: color, border-color, box-shadow;
  transition-property: color, border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 0 0 #fff;
  border-bottom: 1px solid;
  padding: 0 0.539333333333333rem 0.269666666666667rem;
}
.action_anchor_container .action_anchor {
  margin-bottom: 1.618rem;
}
.action_anchor_container .action_anchor:not(:last-child) {
  margin-right: 0.809rem;
}
.action_anchor_container .action_anchor,
.button_container .action_anchor {
  margin-top: 0.3rem;
}
.user_context_actions .action_anchor--primary_action,
.user_context_actions .action_anchor--secondary_action,
.user_context_actions .action_anchor--reductive_action,
.user_context_actions .action_anchor--risk_action {
  box-shadow: inset 0 0 0 #fff;
}
.user_context_actions .action_anchor--primary_action:active,
.user_context_actions .action_anchor--secondary_action:active,
.user_context_actions .action_anchor--reductive_action:active,
.user_context_actions .action_anchor--risk_action:active,
.user_context_actions .action_anchor--primary_action:focus,
.user_context_actions .action_anchor--secondary_action:focus,
.user_context_actions .action_anchor--reductive_action:focus,
.user_context_actions .action_anchor--risk_action:focus,
.user_context_actions .action_anchor--primary_action:hover,
.user_context_actions .action_anchor--secondary_action:hover,
.user_context_actions .action_anchor--reductive_action:hover,
.user_context_actions .action_anchor--risk_action:hover {
  box-shadow: inset 0 -0.66em 0 #fff;
}
.action_anchor--primary_action {
  box-shadow: inset 0 0 0 #e9e0ff;
  border-color: #650df2;
  color: #650df2;
}
.action_anchor--primary_action:active,
.action_anchor--primary_action:focus,
.action_anchor--primary_action:hover {
  box-shadow: inset 0 -0.66em 0 #e9e0ff;
}
.action_anchor--primary_action .triangle__inner,
.action_anchor--primary_action .triangle__path {
  fill: #650df2;
}
.action_anchor--secondary_action {
  box-shadow: inset 0 0 0 #e9e0ff;
  border-color: #7547f5;
  color: #7547f5;
}
.action_anchor--secondary_action:active,
.action_anchor--secondary_action:focus,
.action_anchor--secondary_action:hover {
  box-shadow: inset 0 -0.66em 0 #e9e0ff;
  border-color: #650df2;
  color: #650df2;
}
.action_anchor--secondary_action .triangle__inner {
  fill: transparent;
}
.action_anchor--secondary_action .triangle__path {
  fill: #650df2;
}
.action_anchor--risk_action {
  box-shadow: inset 0 0 0 #ffebec;
  border-color: #fb0e16;
  color: #fb0e16;
}
.action_anchor--risk_action:active,
.action_anchor--risk_action:focus,
.action_anchor--risk_action:hover {
  box-shadow: inset 0 -0.66em 0 #ffebec;
  border-color: #d3030a;
  color: #d3030a;
}
.action_anchor--risk_action .triangle__inner,
.action_anchor--risk_action .triangle__path {
  fill: #fb0e16;
}
.action_anchor--reductive_action {
  box-shadow: inset 0 0 0 #ffebec;
  border-color: #fc4046;
  color: #fc4046;
}
.action_anchor--reductive_action:active,
.action_anchor--reductive_action:focus,
.action_anchor--reductive_action:hover {
  box-shadow: inset 0 -0.66em 0 #ffebec;
  border-color: #fb0e16;
  color: #fb0e16;
}
.action_anchor--reductive_action .triangle__inner {
  fill: transparent;
}
.action_anchor--reductive_action .triangle__path {
  fill: #fc4046;
}
.action_anchor--disabled {
  cursor: not-allowed;
  border-color: #b2afb6;
  color: #b2afb6;
}
.action_anchor--disabled:active,
.action_anchor--disabled:focus,
.action_anchor--disabled:hover {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 #fff;
  border-color: #b2afb6;
  color: #b2afb6;
}
.action_anchor--backwards_action:active .triangle,
.action_anchor--backwards_action:focus .triangle,
.action_anchor--backwards_action:hover .triangle {
  animation: action-anchor-interaction-backwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--forwards_action:active .triangle,
.action_anchor--forwards_action:focus .triangle,
.action_anchor--forwards_action:hover .triangle {
  animation: action-anchor-interaction-forwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--downwards_action:active .triangle,
.action_anchor--downwards_action:focus .triangle,
.action_anchor--downwards_action:hover .triangle {
  animation: action-anchor-interaction-downwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor__text {
  flex: 0 0 auto;
  word-break: keep-all;
  font-weight: 600;
  font-size: 18px;
}
.action_anchor .triangle {
  flex: 0 0 auto;
}
.action_anchor .triangle--right,
.action_anchor .triangle--left {
  height: 0.7em;
  width: 0.462em;
}
.action_anchor .triangle--right,
.action_anchor .triangle--down {
  margin-left: 0.539333333333333rem;
}
.action_anchor .triangle--left {
  margin-right: 0.539333333333333rem;
}
.action_anchor .triangle--down {
  height: 0.44em;
  width: 0.5852em;
}
.alert {
  position: relative;
  z-index: auto;
  display: block;
  width: 100%;
  max-width: 78rem;
}
* + .alert {
  margin-top: 1.618rem;
}
.alert__notice {
  border-radius: 3px 0 0 3px;
  border-left: 4px solid;
  padding: 1.618rem 2.695588rem 1.618rem 1.618rem;
}
.alert__notice--success {
  border-color: #00ffb7;
  background-color: #e0fff5;
}
.alert__notice--failure {
  border-color: #fb0e16;
  background-color: #ffebec;
}
.alert__close_button {
  position: absolute;
  z-index: auto;
  right: 0.809rem;
  top: 0.809rem;
  height: 1.077588rem;
  width: 1.077588rem;
}
.alert__message {
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  color: #19191a;
}
* + .alert__message {
  margin-top: 0;
}
@media only screen and (min-width: 681px) {
  .alert__message {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .alert__message {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.alerts_and_content_and_form {
  flex: 0 0 auto;
  display: flex-start;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}
@media only screen and (min-width: 881px) {
  .alerts_and_content_and_form {
    flex: 1 1 0;
  }
}
@media only screen and (min-width: 881px) {
  .alerts_and_content_and_form {
    overflow-y: auto;
  }
}
.account_access .alerts_and_content_and_form,
.organisations_and_publications_edit .alerts_and_content_and_form {
  align-items: center;
  justify-content: center;
}
.alerts_and_content_and_form__inner {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 78rem;
}
@media only screen and (min-width: 881px) {
  .alerts_and_content_and_form__inner {
    max-height: 100vh;
  }
}
.account_access .alerts_and_content_and_form__inner,
.organisations_and_publications_edit .alerts_and_content_and_form__inner {
  width: auto;
}
.alerts_and_content_and_form__inner > *:first-child {
  margin-top: 2.427rem;
}
.alerts_and_content_and_form__inner > *:last-child {
  margin-bottom: 2.427rem;
}
.app_signifier {
  display: none;
  padding: 0;
}
@media only screen and (min-width: 881px) {
  .app_signifier {
    display: block;
  }
}
@media only screen and (min-width: 881px) {
  .app_signifier {
    padding: 1.618rem 0.809rem 0;
  }
}
html[ping-app-name="reference"] .app_signifier__vector {
  height: 9.3rem;
  width: 3.236rem;
}
html[ping-app-name="central"] .app_signifier__vector {
  height: 10.2rem;
  width: 2.427rem;
}
html[ping-app-name="ingest"] .app_signifier__vector {
  height: 8.3rem;
  width: 2.8315rem;
}
.authentication_token__token_id_and_copy_button {
  display: block;
}
* + .authentication_token__token_id_and_copy_button {
  margin-top: 1.618rem;
}
.authentication_token__token_id {
  display: block;
  width: 100%;
  max-width: 40ch;
  border-left: 4px solid #00ffb7;
  border-radius: 3px 0 0 3px;
  padding-left: 1.077588rem;
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
  color: #5e5c61;
  background-color: #f5f5f5;
}
@media only screen and (min-width: 681px) {
  .authentication_token__token_id {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .authentication_token__token_id {
    font-size: 1.4884rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.breadcrumbs {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
@media only screen and (min-width: 881px) {
  .breadcrumbs {
    position: absolute;
    z-index: auto;
    left: 0;
    bottom: 0;
  }
}
.breadcrumbs__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3a393c;
  padding: 0.3845rem 0.809rem 0.4245rem 4vw;
  line-height: 1.4;
}
@media only screen and (min-width: 881px) {
  .breadcrumbs__list {
    padding: 0.3845rem 0.809rem 0.4245rem;
  }
}
.breadcrumbs__item {
  flex: 0 0 auto;
  display: flex;
  font-size: 0;
}
.breadcrumbs__item + .breadcrumbs__item::before {
  content: '/';
  flex: 0 0 auto;
  display: flex;
  margin-right: 0.539333333333333rem;
  margin-left: 0.539333333333333rem;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #b2afb6;
}
@media only screen and (min-width: 681px) {
  .breadcrumbs__item + .breadcrumbs__item::before {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .breadcrumbs__item + .breadcrumbs__item::before {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.breadcrumbs__anchor {
  will-change: box-shadow;
  transition-property: box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  display: inline-block;
  box-shadow: inset 0 0 0 #7547f5;
  text-transform: uppercase;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #f7f6f8;
}
@media only screen and (min-width: 681px) {
  .breadcrumbs__anchor {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .breadcrumbs__anchor {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.breadcrumbs__anchor:active,
.breadcrumbs__anchor:focus,
.breadcrumbs__anchor:hover {
  box-shadow: inset 0 -0.36em 0 #7547f5;
  color: #fff;
}
.breadcrumbs__decorative_triangle {
  flex: 0 0 auto;
  height: 2rem;
  max-height: 100%;
  width: 1rem;
}
@media only screen and (max-width: 880px) {
  .breadcrumbs__decorative_triangle {
    transform: scaleY(-1);
    filter: FlipV;
  }
}
.breadcrumbs__decorative_triangle_path {
  fill: #3a393c;
}
.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -1.618rem;
  width: 100%;
}
* + .button_container {
  margin-top: 1.618rem;
}
.button {
  will-change: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-property: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  cursor: pointer;
  flex: 0 0 auto;
  border-radius: 3px;
  border: 1px solid;
  padding: 0.269666666666667rem 0.539333333333333rem 0.4045rem;
  word-break: keep-all;
  font-weight: 600;
}
.button:active,
.button:focus,
.button:hover {
  transform: translateY(-2px);
}
.button_container .button {
  margin-bottom: 1.618rem;
}
.button_container .button:not(:last-child) {
  margin-right: 0.809rem;
}
.button--primary_action {
  box-shadow: inset 0 -2px 0 #520ab8;
  border-color: #520ab8;
  background-color: #7547f5;
  text-shadow: 0 0 0.24em #520ab8;
  color: #fff;
}
.button--primary_action:active,
.button--primary_action:focus,
.button--primary_action:hover {
  box-shadow: inset 0 -1px 0 #520ab8;
  background-color: #650df2;
}
.button--secondary_action {
  box-shadow: inset 0 -3px 0 #e9e0ff;
  border-color: #650df2;
  background-color: #f5f5f5;
  color: #650df2;
}
.button--secondary_action:active,
.button--secondary_action:focus,
.button--secondary_action:hover {
  box-shadow: inset 0 -1px 0 #e9e0ff;
  color: #520ab8;
}
.button--reductive_action {
  box-shadow: inset 0 -3px 0 #ffebec;
  border-color: #fb0e16;
  background-color: #f5f5f5;
  color: #fb0e16;
}
.button--reductive_action:active,
.button--reductive_action:focus,
.button--reductive_action:hover {
  box-shadow: inset 0 -1px 0 #ffebec;
  border-color: #d3030a;
  color: #d3030a;
}
.button--risk_action {
  box-shadow: inset 0 -2px 0 #d3030a;
  border-color: #d3030a;
  background-color: #fb0e16;
  color: #fff;
}
.button--risk_action:active,
.button--risk_action:focus,
.button--risk_action:hover {
  box-shadow: inset 0 -1px 0 #d3030a;
}
.button--disabled {
  box-shadow: inset 0 -3px 0 #cccace;
  border-color: #b2afb6;
  background-color: #f7f6f8;
  color: #b2afb6;
}
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  transform: translateY(0);
}
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  cursor: not-allowed;
}
.chosen-container {
  position: relative;
  z-index: 10;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  padding-bottom: 2px;
}
.chosen-container .chosen-drop {
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100% 100%);
  position: absolute;
  z-index: auto;
  top: 100%;
  left: 0;
  border: 1px solid #650df2;
  border-radius: 0 0 3px 3px;
  width: 100%;
  background-color: #f5f5f5;
}
.chosen-container.chosen-with-drop .chosen-drop {
  clip: auto;
  clip-path: none;
}
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 24rem;
  background-color: #f5f5f5;
}
.chosen-container .chosen-results li {
  will-change: background-color, color;
  transition-property: background-color, color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  display: none;
  word-wrap: break-word;
  padding: 0.539333333333333rem 2.427rem 0.539333333333333rem 0.6472rem;
}
.chosen-container .chosen-results li.active-result,
.chosen-container .chosen-results li.disabled-result,
.chosen-container .chosen-results li.no-results {
  display: list-item;
}
.chosen-container .chosen-results li.disabled-result {
  color: #cccace;
}
.chosen-container .chosen-results li.no-results {
  color: #fc4046;
}
.chosen-container .chosen-results li.active-result {
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  cursor: default;
  color: #e5e4e7;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #e9e0ff;
  color: #19191a;
}
.chosen-container-multi .chosen-choices-outer {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.chosen-container-multi .chosen-choices {
  cursor: text;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  height: 99%;
  width: 100%;
  border-radius: 3px 3px 0 0;
  background-color: #fafafa;
  padding: 0 2.427rem 0.3236rem 0.45rem;
}
.chosen-container-multi .chosen-choices li.search-field:not(:first-child),
.chosen-container-multi .chosen-choices li.search-field:not(:first-child) input {
  flex: 0 0 1px;
}
.chosen-container-multi .chosen-choices li.search-field {
  display: flex;
  margin: 0.3236rem 0 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input {
  padding: 0;
  background-color: #fafafa;
}
.chosen-container-multi .chosen-choices li.search-choice {
  cursor: default;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3236rem 0.3236rem 0 0;
  border: 1px solid #00ffb7;
  max-width: 100%;
  background-color: #fafafa;
  padding: 0 0.539333333333333rem 0 0.4045rem;
  line-height: 1.4;
}
.chosen-container-multi .chosen-choices li.search-choice span {
  display: inline-block;
  word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  display: inline-block;
  margin-left: 0.3236rem;
  height: 0.539333333333333rem;
  width: 0.539333333333333rem;
  background-image: url("../images/chosen_cross.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  cursor: default;
  color: #b2afb6;
}
.copy_story {
  position: relative;
  z-index: 80;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  margin-left: 0;
  padding: 0;
}
.copy_story__inner {
  position: relative;
  z-index: auto;
  flex: 0 0 auto;
  display: block;
  max-width: 100%;
}
.copy_story__summary {
  display: inline-block;
}
.copy_story__summary::-webkit-details-marker {
  display: none;
}
.copy_story__summary_text {
  will-change: border-color, background-color, color;
  transition-property: border-color, background-color, color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  cursor: pointer;
  display: flex;
  border: 1px solid #e9e0ff;
  border-radius: 3px;
  padding: 0.269666666666667rem 0.539333333333333rem;
  word-break: break-all;
  text-transform: uppercase;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #650df2;
  font-weight: 600;
}
@media only screen and (min-width: 881px) {
  .copy_story__summary_text {
    padding: 0.539333333333333rem 0.1618rem 0.539333333333333rem 0.20225rem;
  }
}
@media only screen and (min-width: 681px) {
  .copy_story__summary_text {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .copy_story__summary_text {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.copy_story__summary_text::selection {
  background-color: inherit;
  text-shadow: unset;
  color: inherit;
}
details[open] .copy_story__summary_text,
.copy_story__summary_text:active,
.copy_story__summary_text:focus,
.copy_story__summary_text:hover {
  border-color: #520ab8;
  background-color: #650df2;
  color: #fff;
}
.copy_story__options_list {
  z-index: 90;
  position: absolute;
  top: calc(100% + 0.809rem);
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #650df2;
  background-color: #f5f5f5;
  padding: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .copy_story__options_list {
    top: 0;
  }
}
@media only screen and (min-width: 881px) {
  .copy_story__options_list {
    right: unset;
  }
}
@media only screen and (min-width: 881px) {
  .copy_story__options_list {
    left: 3.236rem;
  }
}
@media only screen and (min-width: 881px) {
  .copy_story__options_list {
    width: 19rem;
  }
}
details[open] .copy_story__options_list::before {
  content: '';
  position: absolute;
  top: -0.20225rem;
  left: -0.539333333333333rem;
  border-top: 1px solid #650df2;
  border-left: 1px solid #650df2;
  height: 1.077588rem;
  width: 1.077588rem;
  background-color: #f5f5f5;
}
@media only screen and (min-width: 881px) {
  details[open] .copy_story__options_list::before {
    top: 1.887666666666667rem;
  }
}
@media only screen and (max-width: 880px) {
  details[open] .copy_story__options_list::before {
    left: 50%;
  }
}
.copy_story__options_item {
  flex: 0 0 auto;
}
* + .copy_story__options_item {
  margin-top: 1.618rem;
}
.copy_story__options_anchor {
  color: #650df2;
}
.copy_story__options_anchor:active,
.copy_story__options_anchor:focus,
.copy_story__options_anchor:hover {
  color: #520ab8;
}
.current_filter_parameter {
  display: inline-block;
  box-shadow: inset 0 -0.22em 0 #ffeb51;
  line-height: 1.2;
}
.definitions_and_options {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 3px;
  border: 2px dashed #e5e4e7;
}
@media only screen and (min-width: 881px) {
  .definitions_and_options {
    flex-direction: row;
  }
}
* + .definitions_and_options {
  margin-top: 1.618rem;
}
.definitions_and_options__definition_list {
  flex: 1 1 auto;
  padding: 0.809rem;
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__definition_list {
    padding: 1.618rem;
  }
}
.definitions_and_options__definition_term,
.definitions_and_options__definition_description {
  display: block;
}
.definitions_and_options__definition_term {
  text-transform: uppercase;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .definitions_and_options__definition_term {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 881px) {
  * + .definitions_and_options__definition_term {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .definitions_and_options__definition_term {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .definitions_and_options__definition_term {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.definitions_and_options__definition_term::after {
  content: ':';
}
.definitions_and_options__definition_description {
  border-radius: 3px 0 0 3px;
  border-left: 4px solid #00ffb7;
  padding-left: 0.809rem;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
}
* + .definitions_and_options__definition_description {
  margin-top: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .definitions_and_options__definition_description {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .definitions_and_options__definition_description {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.definitions_and_options__options {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-top: 2px dashed #e5e4e7;
  padding: 0.809rem 0.809rem 0;
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options {
    flex-direction: column;
  }
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options {
    flex-wrap: no-wrap;
  }
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options {
    border-top: 0;
    border-left: 2px dashed #e5e4e7;
  }
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options {
    padding: 1.618rem;
  }
}
.definitions_and_options__options > * {
  flex: 0 0 auto;
  margin-bottom: 0.809rem;
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options > * {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options > *:not(:first-child) {
    margin-top: 1.618rem;
  }
}
.definitions_and_options__options > *:not(:last-child) {
  margin-right: 0.809rem;
}
@media only screen and (min-width: 881px) {
  .definitions_and_options__options > *:not(:last-child) {
    margin-right: 0;
  }
}
.form_component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 4px solid #00ffb7;
  border-radius: 3px 0 0 3px;
  width: 100%;
  padding-left: 1.077588rem;
}
* + .form_component {
  margin-top: 1.618rem;
}
.form_component > * {
  flex: 0 0 auto;
}
* + .form_component--modify {
  margin-top: -0.4045rem;
  padding-top: 2.0225rem;
}
.form_component--modify .form_component__label {
  font-weight: 500;
}
.form_component input[readonly="true"] {
  cursor: not-allowed;
  border-color: #7f7c83;
  background-color: #f5f5f5;
}
.filter_form .form_component {
  border-left: 0;
  border-radius: 0;
  width: auto;
  padding-left: 0;
}
.filter_form * + .form_component {
  margin-top: 0;
}
.form_component__checkbox {
  position: relative;
  z-index: auto;
  display: block;
  padding: 0.269666666666667rem 0.539333333333333rem 0.4045rem;
}
.form_component__checkbox + .form_component__checkbox {
  margin-top: 0.539333333333333rem;
}
.form_component__checkbox__input {
  cursor: pointer;
  z-index: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
}
.form_component__checkbox__label {
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: 1.752833333333333rem;
}
.form_component__checkbox__label::before,
.form_component__checkbox__label::after {
  will-change: opacity, border-color, transform, zoom;
  transition-property: opacity, border-color, transform, zoom;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  content: "";
  position: absolute;
  display: inline-block;
}
.form_component__checkbox__label::before {
  top: 0.24rem;
  left: 0;
  height: 1.2135rem;
  width: 1.2135rem;
  border-radius: 3px;
  border: 1px solid #650df2;
  background-color: #fafafa;
}
.form_component__checkbox__input:focus + .form_component__checkbox__label::before {
  z-index: 90;
  outline-offset: 6px;
  outline: 2px dashed #fa7d00;
  overflow: visible;
}
.form_component__checkbox__input:checked + .form_component__checkbox__label::before {
  border-color: #650df2;
}
.form_component__checkbox__label::after {
  z-index: 110;
  transform: rotate(-10deg) scale(0.4);
  top: 0.52rem;
  left: 0.2rem;
  height: 0.4045rem;
  width: 0.809rem;
  border-bottom: 2px solid #650df2;
  border-left: 2px solid #650df2;
}
.form_component__checkbox__input + .form_component__checkbox__label::after {
  opacity: 0;
}
.form_component__checkbox__input:checked + .form_component__checkbox__label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
.form_component__checkbox__label a,
.form_component__checkbox__label a:visited {
  z-index: 110;
  position: relative;
  color: #650df2;
}
.form_component__checkbox__label a:active,
.form_component__checkbox__label a:visited:active,
.form_component__checkbox__label a:hover,
.form_component__checkbox__label a:visited:hover,
.form_component__checkbox__label a:focus,
.form_component__checkbox__label a:visited:focus {
  color: #520ab8;
}
.form_component--date {
  flex-direction: row;
  align-items: stretch;
  background-color: #fafafa;
}
.form_component--date .form_component__label {
  flex: 0 0 auto;
  position: relative;
  z-index: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
  width: auto;
  max-width: 100%;
  border-radius: 3px 0 0 3px;
  border: 1px solid #650df2;
  border-right: 0;
  border-left-width: 3px;
  background-color: transparent;
  padding: 0.539333333333333rem;
  line-height: 1;
}
.form_component--date .form_component__label:first-child {
  margin-top: 0;
}
.form_component--date .form_component__label::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 20%;
  right: 0;
  height: 60%;
  width: 1px;
  background-color: #e9e0ff;
}
.form_component--date .form_component__label::after {
  content: ":";
}
.form_component__date_inner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: inset 0 -3px 0 #e9e0ff;
  width: 76ch;
  max-width: 100%;
}
.form_component__date_inner:active,
.form_component__date_inner:focus,
.form_component__date_inner:hover {
  box-shadow: inset 0 -1px 0 #e9e0ff;
}
.filter_form .form_component__date_inner {
  width: 100%;
}
.form_component__date {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  flex: 1 1 0;
  cursor: pointer;
  height: 2.4rem;
  width: auto;
  max-width: 100%;
  box-shadow: none;
  border-radius: 0 3px 3px 0;
  border: 1px solid #650df2;
  border-left: 0;
  background-color: transparent;
  padding: 0.539333333333333rem;
  line-height: 1;
  font-size: 1rem;
}
.form_component__date::-webkit-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__date:-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__date::-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__date:-ms-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__date::-webkit-inner-spin-button {
  display: none;
}
.form_component__date::-webkit-calendar-picker-indicator {
  color: #650df2;
}
.form_component__date:active,
.form_component__date:focus,
.form_component__date:hover {
  outline: 0;
}
.form_component__date::-webkit-calendar-picker-indicator,
.form_component__date::-webkit-clear-button,
.form_component__date::-webkit-inner-spin-button {
  opacity: 1;
}
.filter_form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
/*
     * For loop fix for mobile layout search filters.
     * For each nth element matching the selector,
     * set z-index to -1 previous element. This esures
     * chosen.js dropdowns appear with the correct z-index
     */
}
.filter_form > .form_component--date,
.filter_form > .form_component--text_container,
.filter_form > .form_component__select_container,
.filter_form__ordering_and_actions {
  flex: 0 0 100%;
}
@media only screen and (min-width: 681px) {
  .filter_form > .form_component--date,
  .filter_form > .form_component--text_container,
  .filter_form > .form_component__select_container,
  .filter_form__ordering_and_actions {
    flex: 0 0 49.5%;
  }
}
@media only screen and (min-width: 1081px) {
  .filter_form > .form_component--date,
  .filter_form > .form_component--text_container,
  .filter_form > .form_component__select_container,
  .filter_form__ordering_and_actions {
    flex: 0 0 32.666%;
  }
}
@media only screen and (min-width: 1481px) {
  .filter_form > .form_component--date,
  .filter_form > .form_component--text_container,
  .filter_form > .form_component__select_container,
  .filter_form__ordering_and_actions {
    flex: 0 0 24.25%;
  }
}
@media only screen and (max-width: 680px) {
  .filter_form > .form_component--date:not(:first-child),
  .filter_form > .form_component--text_container:not(:first-child),
  .filter_form > .form_component__select_container:not(:first-child),
  .filter_form__ordering_and_actions:not(:first-child) {
    margin-top: 0.809rem;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .filter_form > .form_component--date:nth-child(n+3),
  .filter_form > .form_component--text_container:nth-child(n+3),
  .filter_form > .form_component__select_container:nth-child(n+3),
  .filter_form__ordering_and_actions:nth-child(n+3) {
    margin-top: 1%;
  }
}
@media only screen and (min-width: 1081px) and (max-width: 1480px) {
  .filter_form > .form_component--date:nth-child(n+4),
  .filter_form > .form_component--text_container:nth-child(n+4),
  .filter_form > .form_component__select_container:nth-child(n+4),
  .filter_form__ordering_and_actions:nth-child(n+4) {
    margin-top: 1%;
  }
}
@media only screen and (min-width: 1481px) {
  .filter_form > .form_component--date:nth-child(n+5),
  .filter_form > .form_component--text_container:nth-child(n+5),
  .filter_form > .form_component__select_container:nth-child(n+5),
  .filter_form__ordering_and_actions:nth-child(n+5) {
    margin-top: 1%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .filter_form .form_component__select_container:nth-child(even),
  .filter_form .form_component--text_container:nth-child(even),
  .filter_form .form_component--date:nth-child(even) {
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1081px) and (max-width: 1480px) {
  .filter_form .form_component__select_container:nth-child(3n+2),
  .filter_form .form_component--text_container:nth-child(3n+2),
  .filter_form .form_component--date:nth-child(3n+2),
  .filter_form .form_component__select_container:nth-child(3n+3),
  .filter_form .form_component--text_container:nth-child(3n+3),
  .filter_form .form_component--date:nth-child(3n+3) {
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1481px) {
  .filter_form .form_component__select_container:nth-child(4n+2),
  .filter_form .form_component--text_container:nth-child(4n+2),
  .filter_form .form_component--date:nth-child(4n+2),
  .filter_form .form_component__select_container:nth-child(4n+3),
  .filter_form .form_component--text_container:nth-child(4n+3),
  .filter_form .form_component--date:nth-child(4n+3),
  .filter_form .form_component__select_container:nth-child(4n+4),
  .filter_form .form_component--text_container:nth-child(4n+4),
  .filter_form .form_component--date:nth-child(4n+4) {
    margin-left: 1%;
  }
}
.filter_form .form_component__select_container {
  max-width: none;
}
.filter_form .form_component__select_container.chosen-drop {
  z-index: 100;
}
.filter_form .form_component__select_container:nth-last-child(1),
.filter_form .chosen-container:nth-last-child(1),
.filter_form .filter_form__ordering_and_actions:nth-last-child(1) {
  z-index: 1;
}
.filter_form .form_component__select_container:nth-last-child(2),
.filter_form .chosen-container:nth-last-child(2),
.filter_form .filter_form__ordering_and_actions:nth-last-child(2) {
  z-index: 2;
}
.filter_form .form_component__select_container:nth-last-child(3),
.filter_form .chosen-container:nth-last-child(3),
.filter_form .filter_form__ordering_and_actions:nth-last-child(3) {
  z-index: 3;
}
.filter_form .form_component__select_container:nth-last-child(4),
.filter_form .chosen-container:nth-last-child(4),
.filter_form .filter_form__ordering_and_actions:nth-last-child(4) {
  z-index: 4;
}
.filter_form .form_component__select_container:nth-last-child(5),
.filter_form .chosen-container:nth-last-child(5),
.filter_form .filter_form__ordering_and_actions:nth-last-child(5) {
  z-index: 5;
}
.filter_form .form_component__select_container:nth-last-child(6),
.filter_form .chosen-container:nth-last-child(6),
.filter_form .filter_form__ordering_and_actions:nth-last-child(6) {
  z-index: 6;
}
.filter_form .form_component__select_container:nth-last-child(7),
.filter_form .chosen-container:nth-last-child(7),
.filter_form .filter_form__ordering_and_actions:nth-last-child(7) {
  z-index: 7;
}
.filter_form .form_component__select_container:nth-last-child(8),
.filter_form .chosen-container:nth-last-child(8),
.filter_form .filter_form__ordering_and_actions:nth-last-child(8) {
  z-index: 8;
}
.filter_form .form_component__select_container:nth-last-child(9),
.filter_form .chosen-container:nth-last-child(9),
.filter_form .filter_form__ordering_and_actions:nth-last-child(9) {
  z-index: 9;
}
.filter_form .form_component__select_container:nth-last-child(10),
.filter_form .chosen-container:nth-last-child(10),
.filter_form .filter_form__ordering_and_actions:nth-last-child(10) {
  z-index: 10;
}
.filter_form .form_component__select_container:nth-last-child(11),
.filter_form .chosen-container:nth-last-child(11),
.filter_form .filter_form__ordering_and_actions:nth-last-child(11) {
  z-index: 11;
}
.filter_form .form_component__select_container:nth-last-child(12),
.filter_form .chosen-container:nth-last-child(12),
.filter_form .filter_form__ordering_and_actions:nth-last-child(12) {
  z-index: 12;
}
.filter_form .form_component__select_container:nth-last-child(13),
.filter_form .chosen-container:nth-last-child(13),
.filter_form .filter_form__ordering_and_actions:nth-last-child(13) {
  z-index: 13;
}
.filter_form .form_component__select_container:nth-last-child(14),
.filter_form .chosen-container:nth-last-child(14),
.filter_form .filter_form__ordering_and_actions:nth-last-child(14) {
  z-index: 14;
}
.filter_form .form_component__select_container:nth-last-child(15),
.filter_form .chosen-container:nth-last-child(15),
.filter_form .filter_form__ordering_and_actions:nth-last-child(15) {
  z-index: 15;
}
.filter_form .form_component__select_container:nth-last-child(16),
.filter_form .chosen-container:nth-last-child(16),
.filter_form .filter_form__ordering_and_actions:nth-last-child(16) {
  z-index: 16;
}
.filter_form .form_component__select_container:nth-last-child(17),
.filter_form .chosen-container:nth-last-child(17),
.filter_form .filter_form__ordering_and_actions:nth-last-child(17) {
  z-index: 17;
}
.filter_form .form_component__select_container:nth-last-child(18),
.filter_form .chosen-container:nth-last-child(18),
.filter_form .filter_form__ordering_and_actions:nth-last-child(18) {
  z-index: 18;
}
.filter_form .form_component__select_container:nth-last-child(19),
.filter_form .chosen-container:nth-last-child(19),
.filter_form .filter_form__ordering_and_actions:nth-last-child(19) {
  z-index: 19;
}
.filter_form .form_component__select_container:nth-last-child(20),
.filter_form .chosen-container:nth-last-child(20),
.filter_form .filter_form__ordering_and_actions:nth-last-child(20) {
  z-index: 20;
}
.filter_form .form_component__select_container:nth-last-child(21),
.filter_form .chosen-container:nth-last-child(21),
.filter_form .filter_form__ordering_and_actions:nth-last-child(21) {
  z-index: 21;
}
.filter_form .form_component__select_container:nth-last-child(22),
.filter_form .chosen-container:nth-last-child(22),
.filter_form .filter_form__ordering_and_actions:nth-last-child(22) {
  z-index: 22;
}
.filter_form .form_component__select_container:nth-last-child(23),
.filter_form .chosen-container:nth-last-child(23),
.filter_form .filter_form__ordering_and_actions:nth-last-child(23) {
  z-index: 23;
}
.filter_form .form_component__select_container:nth-last-child(24),
.filter_form .chosen-container:nth-last-child(24),
.filter_form .filter_form__ordering_and_actions:nth-last-child(24) {
  z-index: 24;
}
.filter_form .form_component__select_container:nth-last-child(25),
.filter_form .chosen-container:nth-last-child(25),
.filter_form .filter_form__ordering_and_actions:nth-last-child(25) {
  z-index: 25;
}
.filter_form .form_component__select_container:nth-last-child(26),
.filter_form .chosen-container:nth-last-child(26),
.filter_form .filter_form__ordering_and_actions:nth-last-child(26) {
  z-index: 26;
}
.filter_form .form_component__select_container:nth-last-child(27),
.filter_form .chosen-container:nth-last-child(27),
.filter_form .filter_form__ordering_and_actions:nth-last-child(27) {
  z-index: 27;
}
.filter_form .form_component__select_container:nth-last-child(28),
.filter_form .chosen-container:nth-last-child(28),
.filter_form .filter_form__ordering_and_actions:nth-last-child(28) {
  z-index: 28;
}
.filter_form .form_component__select_container:nth-last-child(29),
.filter_form .chosen-container:nth-last-child(29),
.filter_form .filter_form__ordering_and_actions:nth-last-child(29) {
  z-index: 29;
}
.filter_form .form_component__select_container:nth-last-child(30),
.filter_form .chosen-container:nth-last-child(30),
.filter_form .filter_form__ordering_and_actions:nth-last-child(30) {
  z-index: 30;
}
.filter_form__ordering_and_actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.filter_form__ordering_and_actions .form_component__select_container {
  flex: 1 1 0;
  margin-right: 0.809rem;
}
.filter_form__actions {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
* + .filter_form__actions {
  margin-left: auto;
}
.filter_form__actions > * + * {
  margin-left: 0.809rem;
}
.filter_form * + .button_container,
.filter_form * + .action_anchor_container {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .filter_form * + .button_container,
  .filter_form * + .action_anchor_container {
    margin-top: 1%;
  }
}
* + .form {
  margin-top: 1.618rem;
}
.form *:not(button):required:invalid:focus,
.form *:not(button):required:invalid:active {
  border-color: #fc4046;
}
.form *:not(button):required:valid:focus,
.form *:not(button):required:valid:active {
  border-color: #00ffb7;
}
.form_component__label {
  display: block;
  width: 100%;
  max-width: 76ch;
  color: #3a393c;
}
.form_component__label:first-child {
  margin-top: -0.3236rem;
}
.filter_form .form_component__label {
  width: auto;
  max-width: 100%;
}
.form_component__label--error {
  display: block;
  padding-left: calc(4px + 1.2135rem);
}
* + .form_component__label--error {
  margin-top: 0.539333333333333rem;
}
.form_component__label--error li {
  text-transform: uppercase;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #fb0e16;
}
* + .form_component__label--error li {
  margin-top: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .form_component__label--error li {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .form_component__label--error li {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.form_component__label--error li::before {
  content: "↑";
  display: inline-block;
  margin-right: 0.539333333333333rem;
  font-weight: 700;
  color: hsla(358,97%,62%,0.4);
}
.form_component__number {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  width: 38ch;
  max-width: 100%;
  box-shadow: inset 0 -3px 0 #e9e0ff;
  border-radius: 3px;
  border: 1px solid #650df2;
  border-left-width: 3px;
  background-color: #fafafa;
  padding: 0.539333333333333rem;
}
.form_component__number::-webkit-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__number:-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__number::-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__number:-ms-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__number:active,
.form_component__number:focus,
.form_component__number:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e9e0ff;
  border-color: #520ab8;
}
label + .form_component__number {
  margin-top: 0.539333333333333rem;
}
.form_component__password {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  width: 76ch;
  max-width: 100%;
  box-shadow: inset 0 -3px 0 #e9e0ff;
  border-radius: 3px;
  border: 1px solid #650df2;
  border-left-width: 3px;
  background-color: #fafafa;
  padding: 0.539333333333333rem;
}
.form_component__password::-webkit-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__password:-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__password::-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__password:-ms-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__password:active,
.form_component__password:focus,
.form_component__password:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e9e0ff;
}
label + .form_component__password {
  margin-top: 0.539333333333333rem;
}
.form_component__radio {
  position: relative;
  z-index: auto;
  display: flex;
  width: 100%;
  max-width: 76ch;
}
.form_component__radio + .form_component__radio {
  margin-top: 0.539333333333333rem;
}
.form_component__radio__input {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  opacity: 0;
  margin: 0;
  border: 0;
  height: 100%;
  width: 100%;
  padding: 0;
}
.form_component__radio__label {
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: calc(0.539333333333333rem + 20px);
  color: #3a393c;
}
@media only screen and (max-width: 1080px) {
  .form_component__radio__label {
    padding-top: 0.20225rem;
  }
}
.form_component__radio__label::before,
.form_component__radio__label::after {
  will-change: opacity, border-color, transform, scale;
  transition-property: opacity, border-color, transform, scale;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  content: "";
  position: absolute;
  display: inline-block;
}
.form_component__radio__label::before {
  top: 0.20225rem;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #650df2;
  background-color: #fafafa;
}
html[data-focus-source="key"] .form_component__radio__input:focus + .form_component__radio__label::before,
html[data-focus-source="script"] .form_component__radio__input:focus + .form_component__radio__label::before {
  z-index: 90;
  outline-offset: 6px;
  outline: 2px dashed #fa7d00;
  overflow: visible;
}
.form_component__radio__input:checked + .form_component__radio__label::before,
.form_component__radio__input:focus:checked + .form_component__radio__label::before {
  border-color: #650df2;
}
.form_component__radio__label::after {
  transform: scale(0.1);
  top: calc(0.20225rem + 5px);
  left: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 0;
  background-color: #650df2;
}
.form_component__radio__input + .form_component__radio__label::after {
  opacity: 0;
}
.form_component__radio__input:checked + .form_component__radio__label::after {
  z-index: 90;
  opacity: 1;
  transform: scale(0.8);
}
.form_component__select_container {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 76ch;
}
.filter_form .form_component__select_container {
  width: auto;
}
.form_component__select_container::before {
  content: "";
  transform: translateY(-50%);
  pointer-events: none;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0.809rem;
  height: 0.509rem;
  width: 0.809rem;
  background-image: url("../images/select_triangle.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
label + .form_component__select_container {
  margin-top: 0.539333333333333rem;
}
.form_component__select,
.form_component__select_container .chosen-container {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  cursor: pointer;
  appearance: none;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #650df2;
  border-left-width: 3px;
  background-color: #fafafa;
  background-position: right 0.809rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2135rem;
  color: #3a393c;
}
.form_component__select:active,
.form_component__select_container .chosen-container:active,
.form_component__select:focus,
.form_component__select_container .chosen-container:focus,
.form_component__select:hover,
.form_component__select_container .chosen-container:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e9e0ff;
  border-color: #520ab8;
}
.filter_form .form_component__select,
.filter_form .form_component__select_container .chosen-container {
  height: 2.4rem;
}
.submission_form .form_component__select,
.submission_form .form_component__select_container .chosen-container {
  height: 2.8rem;
}
.form_component__select {
  box-shadow: inset 0 -3px 0 #e9e0ff;
  padding: 0.539333333333333rem 2.427rem 0.539333333333333rem 0.6472rem;
  padding-bottom: calc(0.539333333333333rem + 3px);
}
.filter_form .form_component__select {
  padding: 0.269666666666667rem 2.157333333333333rem 0.4045rem 0.269666666666667rem;
}
.form_component__select_container .chosen-container {
  box-shadow: inset 0 -2px 0 #e9e0ff;
}
.filter_form .form_component--text_container {
  height: 2.4rem;
}
.submission_form .form_component--text_container {
  height: 2.8rem;
}
.form_component__text {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  width: 76ch;
  max-width: 100%;
  box-shadow: inset 0 -3px 0 #e9e0ff;
  border-radius: 3px;
  border: 1px solid #650df2;
  border-left-width: 3px;
  background-color: #fafafa;
  padding: 0.539333333333333rem;
}
.form_component__text::-webkit-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__text:-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__text::-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__text:-ms-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__text:active,
.form_component__text:focus,
.form_component__text:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e9e0ff;
  border-color: #520ab8;
}
.form_component__text:disabled {
  border: 1px solid #5e5c61;
  border-left-width: 3px;
  box-shadow: inset 0 -3px 0 #7f7c83;
}
label + .form_component__text {
  margin-top: 0.539333333333333rem;
}
.filter_form .form_component__text {
  width: 100%;
  padding: 0.269666666666667rem 0.6472rem 0.4045rem;
}
.filter_form .form_component__text::-webkit-input-placeholder {
  color: #3a393c;
}
.filter_form .form_component__text:-moz-placeholder {
  color: #3a393c;
}
.filter_form .form_component__text::-moz-placeholder {
  color: #3a393c;
}
.filter_form .form_component__text:-ms-input-placeholder {
  color: #3a393c;
}
.form_component__textarea {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  resize: none;
  width: 76ch;
  max-width: 100%;
  box-shadow: inset 0 -3px 0 #e9e0ff;
  border-radius: 3px;
  border: 1px solid #650df2;
  border-left-width: 3px;
  background-color: #fafafa;
  padding: 0.539333333333333rem;
}
.form_component__textarea::-webkit-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__textarea:-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__textarea::-moz-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__textarea:-ms-input-placeholder {
  color: #b2afb6;
  opacity: 1;
}
.form_component__textarea:active,
.form_component__textarea:focus,
.form_component__textarea:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e9e0ff;
}
label + .form_component__textarea {
  margin-top: 0.539333333333333rem;
}
.frequently_asked_questions {
  display: block;
  width: 100%;
}
* + .frequently_asked_questions {
  margin-top: 1.618rem;
}
.frequently_asked_questions__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
* + .frequently_asked_questions__list {
  margin-top: 1.618rem;
}
.frequently_asked_questions__item {
  width: 100%;
}
* + .frequently_asked_questions__item {
  margin-top: 1.618rem;
}
.frequently_asked_questions__question_and_answer {
  width: calc(76ch + 1.618rem);
  max-width: 100%;
}
.frequently_asked_questions__question {
  will-change: box-shadow;
  transition-property: box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: block;
  box-shadow: inset 0 -3px 0 #e9e0ff;
  border-radius: 3px;
  border: 1px solid #650df2;
  border-left-width: 4px;
  padding: 0.809rem 2.427rem 0.809rem 0.809rem;
}
.frequently_asked_questions__question:active,
.frequently_asked_questions__question:focus,
.frequently_asked_questions__question:hover,
details[open] .frequently_asked_questions__question {
  box-shadow: inset 0 -1px 0 #e9e0ff;
}
details[open] .frequently_asked_questions__question {
  border-radius: 3px 3px 0 0;
  border-bottom: 0;
}
.frequently_asked_questions__question::-webkit-details-marker {
  display: none;
}
.frequently_asked_questions__question::before {
  content: '▼';
  pointer-events: none;
  transform: scale(1.5, 1) translateX(-10%) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0.809rem;
  color: #650df2;
}
details[open] .frequently_asked_questions__question::before {
  content: '▲';
}
.frequently_asked_questions__question_text {
  color: #650df2;
}
.frequently_asked_questions__question_text::selection {
  background-color: transparent;
  text-shadow: unset;
  color: #650df2;
}
.frequently_asked_questions__answer {
  border-top: 1px solid #650df2;
  border-radius: 0 0 3px 3px;
  padding: 0.809rem;
}
details[open] .frequently_asked_questions__answer {
  box-shadow: inset 3px 0 0 #e9e0ff;
  border: 1px solid #650df2;
}
.organisation_logo {
  flex: 0 0 auto;
  display: block;
  margin-top: auto;
  padding: 0;
}
@media only screen and (min-width: 881px) {
  .organisation_logo {
    padding: 0 0.809rem 1.618rem;
  }
}
.organisation_logo__logotype--horizontal {
  display: block;
  height: 2.427rem;
  width: auto;
}
@media only screen and (min-width: 881px) {
  .organisation_logo__logotype--horizontal {
    display: none;
  }
}
.organisation_logo__logotype--vertical {
  display: none;
  height: auto;
  width: 3.236rem;
}
@media only screen and (min-width: 881px) {
  .organisation_logo__logotype--vertical {
    display: block;
  }
}
.page_uuid {
  position: relative;
  z-index: 80;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.809rem;
  padding: 0;
}
@media only screen and (min-width: 881px) {
  .page_uuid {
    flex: 1;
  }
}
@media only screen and (min-width: 881px) {
  .page_uuid {
    margin-left: 0;
  }
}
@media only screen and (min-width: 881px) {
  .page_uuid {
    padding: 1.618rem 0.809rem;
  }
}
.page_uuid__inner {
  position: relative;
  z-index: auto;
  flex: 0 0 auto;
  display: block;
  max-width: 100%;
}
.page_uuid__details {
  display: flex;
  min-width: 1.618rem;
}
.page_uuid__details[open]::after {
  content: "";
  cursor: url("../images/cross.svg"), zoom-out;
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(224,255,245,0.9);
}
.page_uuid__summary {
  display: inline-block;
}
@media only screen and (min-width: 881px) {
  .page_uuid__summary {
    transform: rotate(180deg);
  }
}
.page_uuid__summary::-webkit-details-marker {
  display: none;
}
.page_uuid__summary_text {
  will-change: border-color, background-color, color;
  transition-property: border-color, background-color, color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  cursor: pointer;
  display: flex;
  border: 1px solid #e9e0ff;
  border-radius: 3px;
  padding: 0.269666666666667rem 0.539333333333333rem;
  word-break: break-all;
  text-transform: uppercase;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #650df2;
}
@media only screen and (min-width: 881px) {
  .page_uuid__summary_text {
    padding: 0.539333333333333rem 0.1618rem 0.539333333333333rem 0.20225rem;
    writing-mode: tb;
  }
}
@media only screen and (min-width: 681px) {
  .page_uuid__summary_text {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .page_uuid__summary_text {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.page_uuid__summary_text::selection {
  background-color: inherit;
  text-shadow: unset;
  color: inherit;
}
details[open] .page_uuid__summary_text,
.page_uuid__summary_text:active,
.page_uuid__summary_text:focus,
.page_uuid__summary_text:hover {
  border-color: #520ab8;
  background-color: #650df2;
  color: #fff;
}
.page_uuid__options_list {
  z-index: 90;
  position: absolute;
  top: calc(100% + 0.809rem);
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #650df2;
  background-color: #f5f5f5;
  padding: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .page_uuid__options_list {
    top: 0;
  }
}
@media only screen and (min-width: 881px) {
  .page_uuid__options_list {
    right: unset;
  }
}
@media only screen and (min-width: 881px) {
  .page_uuid__options_list {
    left: 3.236rem;
  }
}
@media only screen and (min-width: 881px) {
  .page_uuid__options_list {
    width: 19rem;
  }
}
details[open] .page_uuid__options_list::before {
  content: '';
  transform: rotate(45deg) translateX(-50%);
  position: absolute;
  top: -0.20225rem;
  left: -0.539333333333333rem;
  border-top: 1px solid #650df2;
  border-left: 1px solid #650df2;
  height: 1.077588rem;
  width: 1.077588rem;
  background-color: #f5f5f5;
}
@media only screen and (min-width: 881px) {
  details[open] .page_uuid__options_list::before {
    transform: rotate(-45deg);
  }
}
@media only screen and (min-width: 881px) {
  details[open] .page_uuid__options_list::before {
    top: 1.887666666666667rem;
  }
}
@media only screen and (max-width: 880px) {
  details[open] .page_uuid__options_list::before {
    left: 50%;
  }
}
.page_uuid__options_item {
  flex: 0 0 auto;
}
* + .page_uuid__options_item {
  margin-top: 1.618rem;
}
.page_uuid__options_anchor {
  color: #650df2;
}
.page_uuid__options_anchor:active,
.page_uuid__options_anchor:focus,
.page_uuid__options_anchor:hover {
  color: #520ab8;
}
.pagination {
  display: block;
  margin-top: 3.236rem;
  margin-bottom: 1.618rem;
  width: 100%;
}
.pagination__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.pagination__item {
  flex: 0 0 auto;
  margin-bottom: 1.618rem;
}
.pagination__item:first-child {
  margin-right: 0.4045rem;
}
.pagination__item:first-child .triangle {
  margin-right: 0.20225rem;
}
.pagination__item:not(:first-child):not(:last-child) {
  margin-right: 0.4045rem;
  margin-left: 0.4045rem;
}
.pagination__item:last-child {
  margin-left: 0.4045rem;
}
.pagination__item:last-child .triangle {
  margin-left: 0.20225rem;
}
.pagination__anchor {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 #650df2;
  border: 1px solid #650df2;
  height: 2.427rem;
  width: 2.427rem;
  color: #650df2;
}
.pagination__anchor:active,
.pagination__anchor:focus,
.pagination__anchor:hover {
  box-shadow: 2px 2px 0 #650df2;
  color: #520ab8;
}
.pagination__anchor--current_page,
.pagination__anchor--current_page:active,
.pagination__anchor--current_page:focus,
.pagination__anchor--current_page:hover {
  box-shadow: none;
  border: 1px solid #00ebb4;
  border-radius: 50%;
  background-color: #e0fff5;
  color: #00d3a2;
}
.pagination__anchor--arrow {
  box-shadow: none;
  border-radius: 50%;
  border: 0;
  background-color: #650df2;
}
.pagination__anchor--arrow:active,
.pagination__anchor--arrow:focus,
.pagination__anchor--arrow:hover {
  box-shadow: none;
  background-color: #520ab8;
}
.pagination__anchor--arrow .triangle {
  height: 0.809rem;
  width: 0.64rem;
}
.pagination__anchor--arrow .triangle__inner,
.pagination__anchor--arrow .triangle__path {
  fill: #fff;
}
.payment-summary {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: normal;
}
.payment-summary h5 {
  font-weight: bold;
}
.rejection_feedback {
  display: block;
  width: 100%;
  max-width: 100%;
}
* + .rejection_feedback {
  margin-top: 1.618rem;
}
.rejection_feedback:not(:last-child) {
  border-bottom: 2px dashed #e5e4e7;
  padding-bottom: 1.618rem;
}
* + .rejection_feedback__form {
  margin-top: 0.539333333333333rem;
}
* + .rejection_feedback__feedback {
  margin-top: 1.618rem;
}
.rejection_feedback__feedback__text {
  width: 76ch;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
}
* + .rejection_feedback__feedback__text {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .rejection_feedback__feedback__text {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .rejection_feedback__feedback__text {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.rejection_feedback__feedback__text::before {
  content: "\201c";
  display: inline-block;
  margin-right: 0.5ch;
  font-size: 1.481544rem;
  font-size: var(--fz-ratio-power--three);
  color: #00ebb4;
}
@media only screen and (min-width: 681px) {
  .rejection_feedback__feedback__text::before {
    font-size: 1.643032rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  .rejection_feedback__feedback__text::before {
    font-size: 1.815848rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.table--bordered {
  border: 1px solid #00ebb4;
}
.table--bordered th,
.table--bordered td {
  border: 1px solid #00ebb4;
}
.table--bottom-bordered {
  border-bottom: 1px solid #00ebb4;
}
.table--bottom-bordered th,
.table--bottom-bordered td {
  border-bottom: 1px solid #00ebb4;
}
.table--striped tbody tr:nth-child(even) {
  background-color: #f7f6f8;
}
.table--hover tbody tr:hover {
  background-color: #e5e4e7;
}
.table thead {
  background-color: #e0fff5;
  font-weight: 500;
  font-size: 1.14rem;
}
.table tbody {
  font-size: 1.14rem;
}
.table .table_anchor--primary {
  color: #520ab8;
}
.table .table_anchor--secondary {
  color: $cl--brand-third;
}
.table .table_anchor--risk {
  color: #d3030a;
}
.table .table_anchor--disabled {
  color: #b2afb6;
}
.table .table_column_header {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}
.table .table_column_header .table_column_header_text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table .table_sort_anchor {
  display: flex;
  justify-content: center;
}
.table .table_sort_icon {
  height: 20px;
  width: 15px;
}
.tag_chart {
  position: relative;
  z-index: auto;
}
* + .tag_chart {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  .tag_chart__interactivity_toggle_container {
    display: none;
  }
}
.tag_chart__chart_container {
  position: relative;
  z-index: auto;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  min-height: 75vh;
}
* + .tag_chart__chart_container {
  margin-top: 1.618rem;
}
.tag_chart__chart {
  width: 100% !important;
  max-width: 100%;
}
.tag_chart__canvas_touch_cover {
  position: absolute;
  z-index: auto;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
@media only screen and (min-width: 681px) {
  .tag_chart__canvas_touch_cover {
    display: none;
  }
}
.user_context_actions {
  position: relative;
  z-index: 70;
  flex: 0 0 auto;
  border-bottom: 1px solid #3a393c;
  background-color: rgba(0,235,180,0.6);
  padding: 1.077588rem 0;
}
.user_context_actions__inner {
  display: block;
  width: 92%;
  max-width: 78rem;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 681px) {
  .user_context_actions__content_toggle_container {
    display: none;
  }
}
.user_context_actions__content_toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user_context_actions__content_toggle__text,
.user_context_actions__content_toggle__vector {
  flex: 0 0 auto;
}
* + .user_context_actions__content_toggle__vector {
  margin-left: 0.539333333333333rem;
}
.user_context_actions__content_toggle__vector .triangle {
  height: 0.44em;
  width: 0.5852em;
}
.user_context_actions__content_toggle[open] .user_context_actions__content_toggle__vector .triangle {
  transform: rotate(180deg);
}
.user_context_actions__content_toggle__vector .triangle__inner,
.user_context_actions__content_toggle__vector .triangle__path {
  fill: #650df2;
}
.user_context_actions__content {
  display: none;
  width: 100%;
  max-width: 100%;
}
@media only screen and (min-width: 681px) {
  .user_context_actions__content {
    display: block;
  }
}
.user_context_actions__content[open] {
  display: block;
}
* + .user_context_actions__content[open] {
  margin-top: 1.077588rem;
}
.account_access__inner {
  width: 56ch;
  max-width: 100%;
  margin: auto;
}
* + .account_access__inner {
  margin-top: 3.236rem;
}
* + .account_access__notice {
  margin-top: 1.077588rem;
}
.account_access__notice a,
.account_access__notice a:visited {
  color: #650df2;
}
.account_access__notice a:active,
.account_access__notice a:visited:active,
.account_access__notice a:hover,
.account_access__notice a:visited:hover,
.account_access__notice a:focus,
.account_access__notice a:visited:focus {
  color: #520ab8;
}
* + .account_access__form_component {
  margin-top: 1.077588rem;
}
* + .asset_detail__embed_asset,
* + .asset_detail__image_asset,
* + .asset_detail__text_asset,
* + .asset_detail__html_asset {
  margin-top: 3.236rem;
}
.asset_detail__embed_asset,
.asset_detail__text_asset,
.asset_detail__html_asset {
  width: 100%;
  max-width: 86ch;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  .asset_detail__embed_asset,
  .asset_detail__text_asset,
  .asset_detail__html_asset {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .asset_detail__embed_asset,
  .asset_detail__text_asset,
  .asset_detail__html_asset {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.email_settings__inner {
  width: 56ch;
  max-width: 100%;
  margin: auto;
}
* + .email_settings__inner {
  margin-top: 3.236rem;
}
* + .email_settings__notice {
  margin-top: 1.077588rem;
}
.email_settings__notice a,
.email_settings__notice a:visited {
  color: #650df2;
}
.email_settings__notice a:active,
.email_settings__notice a:visited:active,
.email_settings__notice a:hover,
.email_settings__notice a:visited:hover,
.email_settings__notice a:focus,
.email_settings__notice a:visited:focus {
  color: #520ab8;
}
* + .email_settings__form_component {
  margin-top: 1.077588rem;
}
.email_settings__form_radio {
  margin: 0.5em;
}
.email_settings__subheading {
  font-size: 1.2em;
}
.email_settings__button_container {
  margin-top: 1em;
}
.email_settings__verified_flag {
  background-color: #00ffb7;
  font-weight: 400;
  padding: 0.2em;
  margin: 0.1em;
  border-radius: 0.5em;
  border: 1px solid #808080 !important;
  box-shadow: inset 0 -3px 0 #3dffc2;
}
.email_settings__unverified_flag {
  background-color: #fb0e16;
  font-weight: 400;
  padding: 0.2em;
  margin: 0.1em;
  border-radius: 0.5em;
  border: 1px solid #d3030a !important;
  box-shadow: inset 0 -3px 0 #fc4046;
  color: #e5e4e7;
}
.email_settings__primary_flag {
  background-color: #00ffb7;
  font-weight: 400;
  padding: 0.2em;
  margin: 0.1em;
  border-radius: 0.5em;
  border: 1px solid #808080 !important;
  box-shadow: inset 0 -3px 0 #3dffc2;
}
.organisations_and_publications {
  display: block;
  width: 100%;
}
* + .organisations_and_publications {
  margin-top: 1.618rem;
}
.organisations_and_publications__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding-right: 2px;
}
.organisations_and_publications__item {
  flex: 0 0 100%;
}
@media only screen and (min-width: 1081px) {
  .organisations_and_publications__item {
    flex: 0 0 49%;
  }
}
@media only screen and (max-width: 1080px) {
  * + .organisations_and_publications__item {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 1081px) {
  .organisations_and_publications__item:nth-of-type(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1081px) {
  .organisations_and_publications__item:nth-child(n+3) {
    margin-top: 2%;
  }
}
.organisations_and_publications__container {
  will-change: transform, box-shadow;
  transition-property: transform, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  display: block;
  height: 100%;
  box-shadow: 2px 2px 0 #7547f5, 0 0.539333333333333rem 0.6rem rgba(229,228,231,0.34);
  border: 2px solid #7547f5;
  padding: 1.077588rem;
}
.organisations_and_publications__container:active,
.organisations_and_publications__container:focus,
.organisations_and_publications__container:hover {
  transform: translateY(-2px);
}
.organisations_and_publications__container:active,
.organisations_and_publications__container:focus,
.organisations_and_publications__container:hover {
  box-shadow: 4px 4px 0 #650df2, 0 0.539333333333333rem 1.2rem rgba(229,228,231,0.64);
}
.organisations_and_publications__title_divider {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (min-width: 681px) {
  .organisations_and_publications__title_divider::before {
    content: "";
    transform: translateY(10%);
    flex: 0 0 auto;
    display: inline-block;
    border-radius: 50%;
    height: 0.809rem;
    width: 0.809rem;
    background-color: #650df2;
  }
}
.organisations_and_publications__title {
  flex: 1 1 auto;
}
@media only screen and (min-width: 681px) {
  .organisations_and_publications__title {
    margin-left: 0.809rem;
  }
}
.organisations_and_publications__meta_list {
  display: block;
  box-shadow: 1px 1px 0 #00ebb4;
  border: 1px solid #00ebb4;
  width: 100%;
  max-width: 100%;
  padding: 0.1618rem 0.539333333333333rem;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_list {
    flex: 1 1 auto;
  }
}
* + .organisations_and_publications__meta_list {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 1281px) {
  * + .organisations_and_publications__meta_list {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_list {
    padding: 0;
  }
}
.organisations_and_publications__meta_item {
  display: flex;
  flex-direction: column;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 681px) {
  .organisations_and_publications__meta_item {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_item {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
* + .organisations_and_publications__meta_item {
  border-top: 1px solid #00ebb4;
}
.organisations_and_publications__meta_term,
.organisations_and_publications__meta_description {
  flex: 0 0 100%;
  padding: 0.269666666666667rem;
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_term,
  .organisations_and_publications__meta_description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.organisations_and_publications__meta_term {
  font-weight: 500;
  color: #3a393c;
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_term {
    flex: 0 0 12rem;
  }
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 1280px) {
  .organisations_and_publications__meta_term {
    padding-bottom: 0;
  }
}
.organisations_and_publications__meta_term::after {
  content: ":";
}
.organisations_and_publications__meta_description {
  color: #5e5c61;
}
@media only screen and (min-width: 1281px) {
  .organisations_and_publications__meta_description {
    flex: 1 1 auto;
    border-left: 1px solid #00ebb4;
  }
}
@media only screen and (max-width: 1280px) {
  .organisations_and_publications__meta_description {
    padding-top: 0;
  }
}
.package_detail .filter_form__actions_only {
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: -0.809rem;
}
.package_detail .filter_form__actions_only .action_anchor,
.package_detail .filter_form__actions_only .button {
  margin-bottom: 0.809rem;
  margin-right: 0;
}
* + .package_detail .filter_form__actions_only .action_anchor,
* + .package_detail .filter_form__actions_only .button {
  margin-left: 0.809rem;
}
.package_detail .filter_form__actions_only .action_anchor--back_anchor,
.package_detail .filter_form__actions_only .button--back_anchor {
  margin-right: auto;
}
.package_detail__title {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
}
.package_detail__title__title {
  flex: 1 1 auto;
}
.package_detail__title__featured {
  flex: 0 0 auto;
  align-self: flex-start;
}
.package_detail__meta {
  width: 100%;
  display: block;
}
* + .package_detail__meta {
  margin-top: 1.618rem;
}
.package_detail__meta_list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 1px 1px 0 #00ebb4;
  width: 100%;
  max-width: 100%;
  border: 1px solid #00ebb4;
  padding: 0.1618rem 0.539333333333333rem;
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_list {
    flex-direction: row;
  }
}
* + .package_detail__meta_list {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_list {
    padding: 0;
  }
}
.package_detail__meta_item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_item {
    flex: 0 0 50%;
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 681px) {
  .package_detail__meta_item {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_item {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_item:nth-child(odd):last-child {
    flex: 0 0 100%;
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_item:nth-child(even) {
    border-left: 1px solid #00ebb4;
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_item:nth-child(n+3) {
    border-top: 1px solid #00ebb4;
  }
}
@media only screen and (max-width: 1280px) {
  * + .package_detail__meta_item {
    border-top: 1px solid #00ebb4;
  }
}
@media only screen and (max-width: 1280px) {
  .package_detail__meta_item--empty {
    display: none;
  }
}
.package_detail__meta_term,
.package_detail__meta_description {
  padding: 0.269666666666667rem;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_term,
  .package_detail__meta_description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.package_detail__meta_term a,
.package_detail__meta_description a,
.package_detail__meta_term a:visited,
.package_detail__meta_description a:visited {
  color: #650df2;
}
.package_detail__meta_term a:active,
.package_detail__meta_description a:active,
.package_detail__meta_term a:visited:active,
.package_detail__meta_description a:visited:active,
.package_detail__meta_term a:hover,
.package_detail__meta_description a:hover,
.package_detail__meta_term a:visited:hover,
.package_detail__meta_description a:visited:hover,
.package_detail__meta_term a:focus,
.package_detail__meta_description a:focus,
.package_detail__meta_term a:visited:focus,
.package_detail__meta_description a:visited:focus {
  color: #520ab8;
}
.package_detail__meta_term {
  flex: 0 0 auto;
  font-weight: 500;
  color: #3a393c;
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_term {
    flex: 0 0 16rem;
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 1280px) {
  .package_detail__meta_term {
    padding-bottom: 0;
  }
}
.package_detail__meta_term::after {
  content: ":";
}
.package_detail__meta_description {
  flex: 0 0 auto;
  color: #5e5c61;
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_description {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 1280px) {
  .package_detail__meta_description {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__meta_description {
    flex: 1 1 auto;
    border-left: 1px solid #00ebb4;
  }
}
* + .package_detail__assets {
  margin-top: 3.236rem;
}
* + .package_detail__assets_list {
  margin-top: 1.618rem;
}
.package_detail__assets_item {
  will-change: border-left-color;
  transition-property: border-left-color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  position: relative;
  z-index: auto;
  border-radius: 3px 0 0 3px;
  border-left: 2px solid #00ffb7;
  padding-top: 0.809rem;
  padding-bottom: 0.809rem;
  padding-left: 1.077588rem;
}
@media only screen and (max-width: 880px) {
  * + .package_detail__assets_item {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item {
    border-left: 2px solid transparent;
  }
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item {
    padding-left: 4.045rem;
  }
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item:active,
  .package_detail__assets_item:focus,
  .package_detail__assets_item:hover {
    border-left-color: #00ffb7;
  }
}
.package_detail__assets_item--text_asset p {
  width: 100%;
  max-width: 86ch;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
}
* + .package_detail__assets_item--text_asset p {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 881px) {
  * + .package_detail__assets_item--text_asset p {
    margin-top: 0;
  }
}
@media only screen and (min-width: 681px) {
  .package_detail__assets_item--text_asset p {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__assets_item--text_asset p {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.package_detail__assets_item_details_anchor {
  will-change: opacity;
  transition-property: opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item_details_anchor {
    position: absolute;
    top: 0;
    right: calc(100% - 4.045rem);
    bottom: 0;
    left: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.1rem;
  }
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item:active .package_detail__assets_item_details_anchor,
  .package_detail__assets_item:focus .package_detail__assets_item_details_anchor,
  .package_detail__assets_item:hover .package_detail__assets_item_details_anchor {
    opacity: 1;
  }
}
.package_detail__assets_item_details_anchor_text {
  will-change: border-color, background-color, color;
  transition-property: border-color, background-color, color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  border-radius: 3px;
  border: 1px solid #e9e0ff;
  background-color: #f5f5f5;
  padding: 0.269666666666667rem 0.539333333333333rem;
  line-height: 1.2;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #650df2;
  font-weight: 600;
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item_details_anchor_text {
    transform: rotate(180deg);
  }
}
@media only screen and (min-width: 881px) {
  .package_detail__assets_item_details_anchor_text {
    padding: 0.539333333333333rem 0.269666666666667rem 0.539333333333333rem 0.20225rem;
    writing-mode: tb;
    text-align: center;
  }
}
@media only screen and (min-width: 681px) {
  .package_detail__assets_item_details_anchor_text {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .package_detail__assets_item_details_anchor_text {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.package_detail__assets_item_details_anchor_text:active,
.package_detail__assets_item_details_anchor_text:focus,
.package_detail__assets_item_details_anchor_text:hover {
  border-color: #520ab8;
  background-color: #650df2;
  color: #fff;
}
.package_detail__assets_item_image_asset,
.package_detail__assets_item_video_asset {
  height: auto;
  max-height: 40rem;
  width: auto;
  max-width: 86ch;
}
.package_detail .wp-block-image {
  height: auto;
  max-height: 40rem;
  width: auto;
  max-width: 86ch;
}
.package_detail .wp-block-image img {
  max-height: inherit;
  max-width: 100%;
}
.package_detail .package_detail__ctas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
}
.package_detail .package_detail__ctas > * + * {
  margin-top: 10px;
}
.package_detail .package_detail__ctas .left-actions {
  display: flex;
  flex-direction: column;
}
.package_detail .package_detail__ctas .left-actions > * + * {
  margin-top: 10px;
}
@media only screen and (min-width: 881px) {
  .package_detail .package_detail__ctas {
    flex-direction: row;
    justify-content: space-between;
  }
  .package_detail .package_detail__ctas > * + * {
    margin-top: 0;
    margin-left: 0;
  }
  .package_detail .package_detail__ctas .left-actions {
    flex-direction: row;
  }
  .package_detail .package_detail__ctas .left-actions > * + * {
    margin-top: 0;
    margin-left: 15px;
  }
  .package_detail .package_detail__ctas#ctas2 {
    padding-bottom: 20px;
  }
}
.package_list__list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
* + .package_list__list {
  margin-top: 1.618rem;
}
.package_list__item {
  flex: 0 0 auto;
  width: 100%;
  max-width: 78rem;
}
* + .package_list__item {
  margin-top: 2.0741616rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + .package_list__item {
    margin-top: 2.3002448rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + .package_list__item {
    margin-top: 2.5421872rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
.package_list__anchor {
  will-change: transform, box-shadow;
  transition-property: transform, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 2px 2px 0 #7547f5, 0 0.539333333333333rem 0.6rem rgba(229,228,231,0.34);
  border: 2px solid #7547f5;
  padding: 1.077588rem;
}
.package_list__anchor:active,
.package_list__anchor:focus,
.package_list__anchor:hover {
  transform: translateY(-2px);
}
.package_list__anchor:active,
.package_list__anchor:focus,
.package_list__anchor:hover {
  box-shadow: 4px 4px 0 #650df2, 0 0.539333333333333rem 1.2rem rgba(229,228,231,0.64);
}
.package_list__title_divider {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.package_list__title_divider__title {
  flex: 1 1 auto;
}
.package_list__title_divider__featured {
  flex: 0 0 auto;
  align-self: start;
}
.package_list__summary_and_meta_list {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 100%;
}
@media only screen and (min-width: 1281px) {
  * + .package_list__summary_and_meta_list {
    margin-top: 1.618rem;
  }
}
.package_list__meta {
  width: 100%;
  display: block;
}
* + .package_list__meta {
  margin-top: 1.618rem;
}
.package_list__meta_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 1px 1px 0 #00ebb4;
  width: 100%;
  max-width: 100%;
  border: 1px solid #00ebb4;
  padding: 0.1618rem 0.539333333333333rem;
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_list {
    flex-direction: row;
  }
}
* + .package_list__meta_list {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_list {
    padding: 0;
  }
}
.package_list__meta_item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_item {
    flex: 0 0 50%;
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 681px) {
  .package_list__meta_item {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_item {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_item:nth-child(odd):last-child {
    flex: 0 0 100%;
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_item:nth-child(even) {
    border-left: 1px solid #00ebb4;
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_item:nth-child(n+3) {
    border-top: 1px solid #00ebb4;
  }
}
@media only screen and (max-width: 1280px) {
  * + .package_list__meta_item {
    border-top: 1px solid #00ebb4;
  }
}
@media only screen and (max-width: 1280px) {
  .package_list__meta_item--empty {
    display: none;
  }
}
.package_list__meta_term,
.package_list__meta_description {
  padding: 0.269666666666667rem;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_term,
  .package_list__meta_description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.package_list__meta_term a,
.package_list__meta_description a,
.package_list__meta_term a:visited,
.package_list__meta_description a:visited {
  color: #650df2;
}
.package_list__meta_term a:active,
.package_list__meta_description a:active,
.package_list__meta_term a:visited:active,
.package_list__meta_description a:visited:active,
.package_list__meta_term a:hover,
.package_list__meta_description a:hover,
.package_list__meta_term a:visited:hover,
.package_list__meta_description a:visited:hover,
.package_list__meta_term a:focus,
.package_list__meta_description a:focus,
.package_list__meta_term a:visited:focus,
.package_list__meta_description a:visited:focus {
  color: #520ab8;
}
.package_list__meta_term {
  flex: 0 0 auto;
  font-weight: 500;
  color: #3a393c;
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_term {
    flex: 0 0 16rem;
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 1280px) {
  .package_list__meta_term {
    padding-bottom: 0;
  }
}
.package_list__meta_term::after {
  content: ":";
}
.package_list__meta_description {
  flex: 0 0 auto;
  color: #5e5c61;
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_description {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 1280px) {
  .package_list__meta_description {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__meta_description {
    flex: 1 1 auto;
    border-left: 1px solid #00ebb4;
  }
}
.package_list__summary {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.809rem;
  max-width: 100%;
}
@media only screen and (max-width: 880px) {
  .package_list__summary {
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (min-width: 1281px) {
  .package_list__summary {
    margin-top: -0.4045rem;
  }
  * + .package_list__summary {
    margin-left: 1.618rem;
  }
}
.package_list__summary__title,
.package_list__summary_text__title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000;
  font-size: 30px;
  font-style: normal;
}
@media only screen and (max-width: 680px) {
  .package_list__summary,
  .package_list__summary_text {
    font-size: 1.14rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1481px) {
  .package_list__summary,
  .package_list__summary_text {
    font-size: 1.14rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (max-width: 680px) and (min-width: 681px) {
  .package_list__summary,
  .package_list__summary_text {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (max-width: 680px) and (min-width: 1281px) {
  .package_list__summary,
  .package_list__summary_text {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1481px) and (min-width: 681px) {
  .package_list__summary,
  .package_list__summary_text {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1481px) and (min-width: 1281px) {
  .package_list__summary,
  .package_list__summary_text {
    font-size: 1.22rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.package_list__image {
  margin-top: 0px;
  flex-shrink: 0;
  width: 280px;
  height: 280px;
  object-fit: cover;
}
* + .package_list__image {
  margin-left: 1.618rem;
}
@media only screen and (max-width: 880px) {
  .package_list__image {
    width: 200px;
    height: 200px;
  }
}
.package_list__summary_text {
  color: #5e5c61;
}
.payment-history h1 {
  margin-bottom: 20px;
}
.payment-history table {
  border-collapse: collapse;
  width: 100%;
}
.payment-history table th,
.payment-history table td {
  text-align: left;
  padding: 10px 15px;
  border: 1px solid #dcdcdc;
}
.payment-history table th {
  background-color: #f0f0f0;
}
.root_view {
  height: calc(100% - 4.854rem);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.root_view__logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  width: 9.708rem;
}
.root_view__action_anchor {
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
}
* + .root_view__action_anchor {
  margin-top: 3.236rem;
}
@media only screen and (min-width: 681px) {
  .root_view__action_anchor {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .root_view__action_anchor {
    font-size: 1.4884rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.root_view__action_anchor .triangle {
  margin-left: 0.809rem;
}
.root_view__action_anchor.action_anchor {
  box-shadow: inset 0 0 0 #650df2;
}
.root_view__action_anchor.action_anchor:active,
.root_view__action_anchor.action_anchor:focus,
.root_view__action_anchor.action_anchor:hover {
  box-shadow: inset 0 -0.66em 0 hsla(256,100%,94%,0.4);
}
.story_list__title_container {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.story_list.package_list__summary {
  margin-top: 0.5rem;
  word-break: break-all;
}
.highlighted-price {
  color: #228b22;
}
.prorated-amount {
  margin-top: 15px;
}
.tag_list .filter_form .action_anchor_container,
.tag_list .filter_form .button_container {
  justify-content: flex-end;
  margin-bottom: -0.809rem;
}
.tag_list .filter_form .action_anchor_container .action_anchor,
.tag_list .filter_form .button_container .action_anchor,
.tag_list .filter_form .action_anchor_container .button,
.tag_list .filter_form .button_container .button {
  margin-bottom: 0.809rem;
  margin-right: 0;
}
.tag_list .filter_form .action_anchor_container * + .action_anchor,
.tag_list .filter_form .button_container * + .action_anchor,
.tag_list .filter_form .action_anchor_container * + .button,
.tag_list .filter_form .button_container * + .button {
  margin-left: 0.809rem;
}
.tag_list .filter_form .comparison_actions {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
}
@media only screen and (min-width: 681px) {
  .tag_list .filter_form .comparison_actions {
    margin-right: auto;
  }
}
.tag_list__list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
* + .tag_list__list {
  margin-top: 1.618rem;
}
.tag_list__item {
  will-change: transform, box-shadow;
  transition-property: transform, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 2px 2px 0 #3a393c;
  width: 100%;
  max-width: 78rem;
  border: 2px solid #3a393c;
  padding: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .tag_list__item {
    padding: 1.077588rem;
  }
}
* + .tag_list__item {
  margin-top: 2.0741616rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + .tag_list__item {
    margin-top: 2.3002448rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + .tag_list__item {
    margin-top: 2.5421872rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
.tag_list__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}
.tag_list__header > * {
  height: 100%;
}
.tag_list__compare_option {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tag_list__compare_option .form_component__checkbox__label {
  padding-left: 0;
  padding-top: 1.752833333333333rem;
}
.tag_list__compare_option .form_component__checkbox__label::before,
.tag_list__compare_option .form_component__checkbox__label::after {
  transform: translateX(-50%);
}
.tag_list__compare_option .form_component__checkbox__label::before {
  top: 2px;
  height: 18px;
  width: 18px;
  left: 50%;
}
@media only screen and (min-width: 881px) {
  .tag_list__compare_option .form_component__checkbox__label::before {
    top: 6px;
  }
}
.tag_list__compare_option .form_component__checkbox__label::after {
  top: 2px;
  left: calc(50% - 2px);
  height: 6px;
  width: 12px;
}
@media only screen and (min-width: 881px) {
  .tag_list__compare_option .form_component__checkbox__label::after {
    top: 6px;
  }
}
.tag_list__compare_option .form_component__checkbox__input:checked + .form_component__checkbox__label::after {
  transform: rotate(-45deg) scale(1) translateX(-50%);
}
.tag_list__heading_and_priority_information {
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (min-width: 681px) {
  .tag_list__heading_and_priority_information {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 681px) {
  .tag_list__heading_and_priority_information {
    justify-content: space-between;
  }
}
* + .tag_list__heading_and_priority_information {
  margin-left: 0.539333333333333rem;
  border-left: 2px dashed #e5e4e7;
  padding-left: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  * + .tag_list__heading_and_priority_information {
    margin-left: 1.077588rem;
  }
}
@media only screen and (min-width: 681px) {
  * + .tag_list__heading_and_priority_information {
    padding-left: 1.077588rem;
  }
}
.tag_list__heading {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
@media only screen and (min-width: 681px) {
  .tag_list__heading {
    flex: 1 1 0;
  }
}
* + .tag_list__heading {
  margin-top: 0;
}
.tag_list__priority_information {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  .tag_list__priority_information {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 681px) {
  .tag_list__priority_information {
    flex-direction: column;
  }
}
@media only screen and (min-width: 681px) {
  .tag_list__priority_information {
    align-items: center;
  }
}
@media only screen and (min-width: 681px) {
  .tag_list__priority_information {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__priority_information {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 681px) {
  * + .tag_list__priority_information {
    margin-left: 1.077588rem;
    border-left: 2px dashed #e5e4e7;
    padding-left: 1.077588rem;
  }
}
@media only screen and (max-width: 680px) {
  .tag_list__priority_information:not(:last-child)::before {
    content: ",";
    display: inline-block;
    margin-right: 1ch;
  }
}
.tag_list__priority_information__number {
  flex: 0 0 auto;
}
@media only screen and (min-width: 681px) {
  .tag_list__priority_information__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.236rem;
    width: 3.236rem;
    border-radius: 50%;
    border: 1px solid #3dffc2;
    background-color: #e0fff5;
    font-size: 0.87719298245614rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 681px) and (min-width: 681px) {
  .tag_list__priority_information__number {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 681px) and (min-width: 1281px) {
  .tag_list__priority_information__number {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 681px) and (min-width: 681px) {
  .tag_list__priority_information__number {
    font-size: 1.481544rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 681px) and (min-width: 681px) and (min-width: 681px) {
  .tag_list__priority_information__number {
    font-size: 1.643032rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 681px) and (min-width: 681px) and (min-width: 1281px) {
  .tag_list__priority_information__number {
    font-size: 1.815848rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.tag_list__priority_information__label {
  flex: 0 0 auto;
  display: inline-block;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .tag_list__priority_information__label {
  margin-top: 0.20225rem;
}
@media only screen and (min-width: 681px) {
  .tag_list__priority_information__label {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__priority_information__label {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (max-width: 680px) {
  .tag_list__priority_information__label::after {
    content: ":";
    display: inline-block;
    margin-right: 0.5ch;
  }
}
.tag_list__expand_button {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* + .tag_list__expand_button {
  margin-left: 0.539333333333333rem;
  border-left: 2px dashed #e5e4e7;
  padding-left: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  * + .tag_list__expand_button {
    margin-left: 1.077588rem;
  }
}
@media only screen and (min-width: 681px) {
  * + .tag_list__expand_button {
    padding-left: 1.077588rem;
  }
}
.tag_list__expand_button__vector {
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.236rem;
  width: 3.236rem;
  border-radius: 50%;
  border: 1px solid #7547f5;
  background-color: #e9e0ff;
}
.tag_list__item[open] .tag_list__expand_button__vector {
  transform: rotate(180deg);
}
.tag_list__expand_button__vector svg {
  flex: 0 0 auto;
  margin-top: 0.16rem;
  height: 1.077588rem;
  width: 1.3rem;
  fill: #650df2;
}
.tag_list__expand_button__label {
  flex: 0 0 auto;
  display: inline-block;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .tag_list__expand_button__label {
  margin-top: 0.20225rem;
}
@media only screen and (min-width: 681px) {
  .tag_list__expand_button__label {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__expand_button__label {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.tag_list__content {
  display: none;
  width: 100%;
}
* + .tag_list__content {
  margin-top: 1.077588rem;
}
.tag_list__item[open] .tag_list__content {
  display: block;
}
.tag_list__meta_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 1px 1px 0 #00ebb4;
  width: 100%;
  max-width: 100%;
  border: 1px solid #00ebb4;
  padding: 0.1618rem 0.539333333333333rem;
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_list {
    flex: 1 1 auto;
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_list {
    display: block;
  }
}
@media only screen and (min-width: 1281px) {
  * + .tag_list__meta_list {
    margin-left: 1.618rem;
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_list {
    padding: 0;
  }
}
.tag_list__meta_item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 681px) {
  .tag_list__meta_item {
    font-size: 0.847457627118644rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_item {
    font-size: 0.819672131147541rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
* + .tag_list__meta_item {
  border-top: 1px solid #00ebb4;
}
.tag_list__meta_term,
.tag_list__meta_description {
  padding: 0.269666666666667rem;
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_term,
  .tag_list__meta_description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.tag_list__meta_term a,
.tag_list__meta_description a,
.tag_list__meta_term a:visited,
.tag_list__meta_description a:visited {
  color: #650df2;
  font-weight: 400;
}
.tag_list__meta_term a:active,
.tag_list__meta_description a:active,
.tag_list__meta_term a:visited:active,
.tag_list__meta_description a:visited:active,
.tag_list__meta_term a:hover,
.tag_list__meta_description a:hover,
.tag_list__meta_term a:visited:hover,
.tag_list__meta_description a:visited:hover,
.tag_list__meta_term a:focus,
.tag_list__meta_description a:focus,
.tag_list__meta_term a:visited:focus,
.tag_list__meta_description a:visited:focus {
  color: #520ab8;
}
.tag_list__meta_term {
  flex: 0 0 auto;
  font-weight: 500;
  color: #3a393c;
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_term {
    flex: 0 0 12rem;
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 1280px) {
  .tag_list__meta_term {
    padding-bottom: 0;
  }
}
.tag_list__meta_term::after {
  content: ":";
}
.tag_list__meta_description {
  flex: 0 0 auto;
  color: #5e5c61;
}
@media only screen and (min-width: 1281px) {
  .tag_list__meta_description {
    flex: 1 1 auto;
    border-left: 1px solid #00ebb4;
  }
}
@media only screen and (max-width: 1280px) {
  .tag_list__meta_description {
    padding-top: 0;
  }
}
.tag_list__meta_description__chart_container {
  position: relative;
  z-index: auto;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  height: 24vh;
}
.tag_list__meta_description__chart_container canvas {
  width: 100% !important;
  max-width: 100%;
}
.tag_list__count {
  margin-left: 0.25ch;
  font-size: 0.769467528470299rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .tag_list__count {
    font-size: 0.718184429761563rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .tag_list__count {
    font-size: 0.671862402579952rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.terms_and_conditions__pdf_iframe {
  height: 900px;
}
